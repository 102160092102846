var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table-view',{attrs:{"total":_vm.total,"query-info":_vm.queryInfo},on:{"update:queryInfo":function($event){_vm.queryInfo=$event},"update:query-info":function($event){_vm.queryInfo=$event},"reload-table":_vm.renderTable},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('view-search-form',{ref:"searchFormRef",attrs:{"keyword-placeholder":"姓名、学号、录取编号","queryInfo":_vm.queryInfo,"tool-list":['grade', 'college', 'major', 'stuStatus','keyword'],"insert-select-all":[ 'college', 'major']},on:{"update:queryInfo":function($event){_vm.queryInfo=$event},"update:query-info":function($event){_vm.queryInfo=$event},"on-search":function($event){return _vm.renderTable(1)}}})]},proxy:true}])},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData,"border":"","stripe":""}},[_c('el-table-column',{attrs:{"type":"index","label":"ID","width":"55"}}),_c('el-table-column',{attrs:{"prop":"examineNo","label":"录取编号","width":"120"}}),_c('el-table-column',{attrs:{"prop":"stuNo","label":"学号","width":"120","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.stuNo||'--')+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"grade","label":"年级","width":"80"}}),_c('el-table-column',{attrs:{"prop":"stuName","label":"姓名","width":"120"}}),_c('el-table-column',{attrs:{"label":"学籍状态","width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.$http.getParamName(row.stuStatus, _vm.$store.state.systemParam.stuStatus))+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"sexType","label":"性别","width":"80"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(Number(row.sexType) === 1 ? '男' : '女')+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"collegeName","label":"院系","width":"150"}}),_c('el-table-column',{attrs:{"prop":"majorName","label":"专业","width":"200"}}),_c('el-table-column',{attrs:{"prop":"className","label":"班级","width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.className||'未绑定')+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"apartmentName","label":"宿舍","min-width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.dormitoryName && row.dormitoryBedNo)?_c('span',[_vm._v(" "+_vm._s(row.dormitoryName)+_vm._s(row.roomName)+"室"+_vm._s(row.dormitoryBedNo)+"号床")]):_c('span',[_vm._v("未分配")])]}}])}),_c('el-table-column',{attrs:{"prop":"remarks","label":"备注","min-width":"180"}}),_c('el-table-column',{attrs:{"label":"操作","width":"100","fixed":"right","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(!row.stuStatus)?[_c('el-button',{directives:[{name:"permission",rawName:"v-permission",value:(['stu:signIn']),expression:"['stu:signIn']"}],attrs:{"type":"danger","size":"mini"},on:{"click":function($event){return _vm.showDialog( row.id)}}},[_vm._v("注册 ")])]:_vm._e()]}}])})],1),(_vm.visibleDialog)?_c('sign-in',{ref:"dialogRef",attrs:{"visible":_vm.visibleDialog},on:{"update:visible":function($event){_vm.visibleDialog=$event},"on-close":_vm.renderTable}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }