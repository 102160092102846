<template>
  <table-view
    :total="total"
    :query-info.sync="queryInfo"
    @reload-table="renderTable">
    <template #header>
      <view-search-form
        ref="searchFormRef"
        keyword-placeholder="姓名、学号、录取编号"
        :queryInfo.sync="queryInfo"
        :tool-list="['grade', 'college', 'major', 'stuStatus','keyword']"
        :insert-select-all="[ 'college', 'major']"
        @on-search="renderTable(1)" />
    </template>
    <!-- body -->
    <el-table :data="tableData" style="width: 100%" border stripe>
      <el-table-column type="index" label="ID" width="55" />
      <el-table-column prop="examineNo" label="录取编号" width="120" />
      <el-table-column prop="stuNo" label="学号" width="120" align="center">
        <template v-slot="{row}">
          {{ row.stuNo||'--' }}
        </template>
      </el-table-column>
      <el-table-column prop="grade" label="年级" width="80" />
      <el-table-column prop="stuName" label="姓名" width="120" />
      <el-table-column label="学籍状态" width="120">
        <template v-slot="{row}">
          {{ $http.getParamName(row.stuStatus, $store.state.systemParam.stuStatus) }}
        </template>
      </el-table-column>
      <el-table-column prop="sexType" label="性别" width="80">
        <template v-slot="{row}">
          {{ Number(row.sexType) === 1 ? '男' : '女' }}
        </template>
      </el-table-column>
      <el-table-column prop="collegeName" label="院系" width="150" />
      <el-table-column prop="majorName" label="专业" width="200" />
      <el-table-column prop="className" label="班级" width="200">
        <template v-slot="{row}">
          {{ row.className||'未绑定' }}
        </template>
      </el-table-column>
      <el-table-column prop="apartmentName" label="宿舍" min-width="200">
        <template v-slot="{row}">
            <span v-if="row.dormitoryName && row.dormitoryBedNo">
              {{ row.dormitoryName }}{{ row.roomName }}室{{
                row.dormitoryBedNo
              }}号床</span>
          <span v-else>未分配</span>
        </template>
      </el-table-column>
      <el-table-column prop="remarks" label="备注" min-width="180" />
      <el-table-column label="操作" width="100" fixed="right" align="center">
        <template v-slot="{row}">
          <template v-if="!row.stuStatus">
            <el-button
              v-permission="['stu:signIn']"
              type="danger"
              size="mini"
              @click="showDialog( row.id)"
            >注册
            </el-button>
          </template>
        </template>
      </el-table-column>
    </el-table>
    <sign-in ref="dialogRef" :visible.sync="visibleDialog" v-if="visibleDialog" @on-close="renderTable" />
  </table-view>
</template>

<script>
import { getStudentList } from '@/api/student'
import tableView from '@/vue/mixins/table-view'
import SignIn from '@/views/pages/secondCollege/dialog/signIn.vue'

export default {
  components: {
    SignIn
  },
  mixins: [tableView],
  data () {
    return {
      queryInfo: {
        stuStatus: 0, // 0-未注册
        campusId: null,
        grade: new Date().getFullYear(),
        collegeId: '',
        majorId: ''
      },
      stuStatus: this.$store.state.systemParam.stuStatus,
      stuInfo: []
    }
  },
  methods: {
    // 获取学生列表
    async renderTable (pageNum) {
      await this.getTableData(getStudentList, pageNum)
    }
  }
}
</script>
