<template>
  <el-dialog
    class="signIn-dialog"
    title="学生注册"
    :visible.sync="show"
    :close-on-click-modal="false"
    width="720px"
    @close="close"
  >
    <el-form
      ref="form"
      :model="formData"
      :rules="rules"
      label-width="80px"
    >
      <el-row>
        <el-col :span="12">
          <el-form-item label="录取编号" prop="examineNo">
            <el-input
              disabled
              v-model.trim="formData.examineNo" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="考生号" prop="examineCode">
            <el-input disabled v-model.trim="formData.examineCode" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="姓名" prop="stuName">
            <el-input disabled v-model.trim="formData.stuName" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="性别" prop="sexType">
            <el-select
              disabled
              v-model="formData.sexType"
              placeholder="请选择性别"
            >
              <el-option
                v-for="{name, type} in $store.state.systemParam.sexType"
                :label="name"
                :value="type"
                :key="type" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="年级" prop="grade">
            <el-select v-model="formData.grade" disabled @change="changeGrade">
              <el-option
                v-for="{paramValue} in $store.state.systemParam.grade"
                :label="paramValue"
                :value="paramValue"
                :key="paramValue" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="院系" prop="collegeId">
            <el-select v-model="formData.collegeId" disabled>
              <el-option
                v-for="{collegeName, id} in collegeList"
                :label="collegeName"
                :value="id"
                :key="id" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="专业" prop="majorId">
            <el-select v-model="formData.majorId" disabled filterable clearable @change="changeMajor">
              <el-option
                v-for="{majorName, id} in majorList"
                :label="majorName"
                :value="id"
                :key="id" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="班级" prop="classesId">
            <el-select v-model="formData.classesId" filterable clearable>
              <el-option
                v-for="{className, id} in classList"
                :label="className"
                :value="id"
                :key="id" />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="备注" prop="remarks">
        <el-input v-model.trim="formData.remarks" />
      </el-form-item>
      <el-table :data="classList" style="width: 100%" border stripe>
        <el-table-column type="index" label="ID" width="55" />
        <el-table-column prop="className" label="班级" />
        <el-table-column prop="manNum" label="男生" width="60" />
        <el-table-column prop="womanNum" label="女生" width="60" />
        <el-table-column prop="stuNum" label="班内现有人数" width="120" />
      </el-table>
    </el-form>
    <template #footer>
      <el-button @click="close">取 消</el-button>
      <el-button type="danger" @click="save('学生注册信息', addStudent, '注册成功!')">注 册</el-button>
    </template>
  </el-dialog>
</template>

<script>
import { addStudent, getStudentById } from '@/api/student'
import dialog from '@/vue/mixins/dialog'
import campusSchMajorClasses from '@/vue/mixins/campusSchMajorClasses'

export default {
  mixins: [dialog, campusSchMajorClasses],
  data () {
    return {
      formData: {
        id: null,
        userId: '', // 用户id
        stuName: '', // 学生姓名
        examineNo: '', // 录取编号
        sexType: 1, // 性别（1——男，2——女）
        stuStatus: 0, // 学生状态
        middleSchool: '', // 中学名称
        examineCode: '', // 考生号
        // campusId: '', // 校区
        collegeId: '', // 学院
        grade: '', // 年级
        majorId: '', // 专业
        classesId: '', // 班级
        remarks: '' // 备注
      },
      sexType: [],
      collegeList: [],
      majorList: [],
      // campusList: [],
      gradeList: [],
      rules: {
        grade: [{ required: true, message: '请选择年级', trigger: 'blur' }],
        classesId: [{ required: true, message: '请选择班级', trigger: 'blur' }],
        majorId: [{ required: true, message: '请选择专业', trigger: 'blur' }]
      }
    }
  },
  async mounted () {
    await Promise.all([
      this.getCollegeList(),
      this.getMajorList()
    ])
    await this.$nextTick()
    if (this.id) {
      await this.getDataById(this.id, getStudentById)
      await this.getClassList({
        grade: this.formData.grade,
        majorId: this.formData.majorId
      })
    }
    this.formData.stuStatus = 1
  },
  methods: {
    addStudent, // 保存api
    // 根据id获取专业信息
    getMajorById (majorId) {
      this.$http.getMajorById(majorId).then((res) => {
        // this.formData.campusId = res.data.campusId
        this.formData.collegeId = res.data.collegeId
      })
    },
    // 选择年级
    changeGrade (val) {
      this.formData.classesId = ''
      this.getClassList({
        grade: val,
        majorId: this.formData.majorId
      })
    },
    // 选择专业
    changeMajor (majorId) {
      this.formData.collegeId = ''
      // this.formData.campusId = ''
      this.formData.classesId = ''
      this.getMajorById(majorId)
      this.getClassList({
        grade: this.formData.grade,
        majorId: majorId
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.signIn-dialog {
  .el-form {
    padding-bottom: 1rem;
  }
}
</style>
