// 获取 校区/院系/专业/班级的方法汇总 mixins

export default {
  data () {
    return {
      campusList: [], // 校区
      collegeList: [], // 院系
      majorList: [], // 专业
      classList: [] // 班级
    }
  },
  methods: {
    // 获取 校区List
    async getCampusList () {
      try {
        const res = await this.$http.getCampusListQuery()
        this.campusList = res.data
      } catch (e) {
      }
    },
    // 获取 院系List
    async getCollegeList (query = {}) {
      try {
        const res = await this.$http.getCollegeListQuery(query)
        if (this.$store.state.login.userInfo.collegeId) {
          this.collegeList = res.data.filter(({ id }) => id === this.$store.state.login.userInfo.collegeId)
        } else {
          this.collegeList = res.data
        }
      } catch (e) {
      }
    },
    // 获取 List
    async getMajorList (query = {}) {
      try {
        if (this.$store.state.login.userInfo.collegeId) {
          this.$set(query, 'collegeId', this.$store.state.login.userInfo.collegeId)
        }
        const res = await this.$http.getMajorListQuery(query)
        this.majorList = res.data
      } catch (e) {
      }
    },
    // 获取 班级List
    async getClassList (query = {}) {
      try {
        if (this.$store.state.login.userInfo.collegeId) {
          this.$set(query, 'collegeId', this.$store.state.login.userInfo.collegeId)
        }
        const res = await this.$http.getClassListQuery(query)
        this.classList = res.data
      } catch (e) {
      }
    }
  }
}
